<template>
<v-container style="margin-top:130px;"> 
    <v-container v-if="!linked||1">
       <v-card elevation="10" class="rounded-card" style="margin-bottom:25px;">
            <!-- <v-card-title>
        
           <span class="title font-weight-light">
             NICORA</span>
          </v-card-title> -->
           
            <v-img src="img/new_emo3.png" max-width="100%" contain center></v-img>
           
      </v-card>


     
       <v-row fluid>
      
      <v-col cols="12">
      <p style="text-align: center;">
        <br>
             
              <b>Замовляй поповнення мобільного рахунку на суму від 100 грн.
</b>
              <br><br>1 бал = 1 грн поповнення
<br>
              
  
              <br>Бали буде переведено на вказаний Вами номер телефону
              <br><font style="color:red;">Уважно перевірте номер! Повернення коштів переведених на невірно вказаний номер телефону неможливе.</font>
      </p>
      </v-col>
      
      </v-row>

      <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-text-field
            :class="{sinput:$i18n.locale=='ge'}"
              v-model="mob_send_num"
              name="mob_send_num"
              label="Номер поповнення"
              solo
              rounded
               v-maska="'+380 (##) ###-##-##'" 
              inputmode="numeric"
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

      <v-row fluid>
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-text-field
            :class="{sinput:$i18n.locale=='ge'}"
              v-model="mob_send"
              name="mob_send"
              label="Сума поповнення"
              solo
              rounded
              v-maska="'#*'" 
              inputmode="numeric"
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>  

         <v-row fluid>
            <v-col cols="1"></v-col>
            <v-col cols="10">
                <v-btn rounded class="primary_button" @click="withdraw_mob">Замовити</v-btn>
            </v-col>
            <v-col cols="1"></v-col>
      </v-row>







      <v-row fluid>
            <v-col cols="1"></v-col>
            <v-col cols="10">
                <p style="text-align: center;">
              <b>Замовлені поповнення:</b>
            </p>
            </v-col>
            <v-col cols="1"></v-col>
      </v-row>


      
     <v-container>
       <v-container v-for="(order, i) in orders" :key="i">
        <v-card  elevation="10">
          <v-row fluid>
            
              <v-col cols="7" style="display: flex;    align-items: center">
                  <p style="text-align: center;width:100%">
                <b>{{order['a_date']}}</b><br>{{order['add_field']}}
              </p>
              </v-col>
              <v-col cols="5">
                  <p style="text-align: center;">

                <b>{{order['amount']}} UAH</b><br>
                <b v-if="order['r_state']=='2'" style="color:red">Помилка</b>
                <b v-if="order['r_state']=='1'" style="color:green">Поповнено</b>
                <b v-if="order['r_state']=='9'||order['r_state']=='0'" style="color:orange">Очікуємо статус</b>
              </p>
              </v-col>
            
          </v-row>
           
        </v-card>
      </v-container>
      </v-container>



          <v-overlay :value="isLoading">
      <v-progress-circular indeterminate
        color="red">
        
      </v-progress-circular>
      <br />
          </v-overlay>
    </v-container>
</v-container>
</template>

<script>
import btnrnd from "../components/btnrnd.vue";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { API_LOCATION } from "../../config";
export default {
  name: "Info",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    btnrnd,
  },
  data: () => ({
    step_0: false,
    step_1: false,
    sms_e:false,
      date: new Date().toISOString().substr(0, 10),
    linked:true,
    fishka_num:0,
    mob_send:'',
    mob_send_num:'+380',
    amount:null,
    personal_number: null,
    oppa_account:null,
    orders:[],
    transaction_id:"",
    sms_verify_code:"",
    modal: false,
    menu2: false,
    isLoading:false,
    month_lang:{
       
        'ua':['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']
      },
    
  }),
  computed: {
    task1: function () {
      return this.cards.filter((i) => i.type == "1");
    },
    task2: function () {
      return this.cards.filter((i) => i.type == "2");
    },
    task3: function () {
      return this.cards.filter((i) => i.type == "3");
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    }, 
    title() {
        return this.$t('title')
      },

    date_locale(){
        if(this.$i18n.locale=='ru')
        {
            return 'ru-RU';
        }
        else
        {
            return 'ka-GE';
        }
    }
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  mounted() {},
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");
    this.get_mob();
  
  },
  methods:{
    withdraw_mob: function () {
     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "ks_order",
          ks_sum:this.mob_send,
          mob_send_num:this.mob_send_num,
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data); 
        if(resp.data['code']=='OK')
        {
          this.mob_send='';
         this.get_mob();
         alert(this.$t('order_success'));
        }
        else if(resp.data['code']=='insuf')
        {
          alert(this.$t('order_insuf'));

        }
        else if(resp.data['code']=='bad_msisdn')
        {
          alert(this.$t('bad_msisdn'));

        }
        else if(resp.data['code']=='insuf_min')
        {
          alert("Мінімальна сума - 100 грн.");

        }
        else if(resp.data['code']=='insuf_max')
        {
         alert(this.$t('insuf_max'));

        }
        else if(resp.data['code']=='insuf_max_op')
        {
         alert(this.$t('insuf_max_op'));

        }
        else if(resp.data['code']=='next_day_queue')
        {
         alert(this.$t('next_day_queue'));

        }
        else if(resp.data['code']=='next_day_try')
        {
         alert(this.$t('next_day_try'));

        }
        this.isLoading=false;
        
      });
      
    },
    get_mob: function () {
     
      this.isLoading=true;
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_mob_orders" 
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data); 
        this.orders=resp.data;
         this.isLoading=false;
        
      });
      
    },


    
  }
};
</script>

<i18n>
{
  "ua":{
      "title": "Обмін балів",
      
      "cert_txt":"Для замовлення 1 сертифікату Rozetka номіналом 200 грн натисність «Замовити».  ",
      "btn_order":"Замовити",
      "cert_del_txt":"Для використання сертифікату покажіть його на касі перед покупкою у будь-якому вигляді: зображенням у iTeam, скріншотом або роздруківкою.",
      "order_success":"Поповнення замовлено",
      "order_insuf":"Недостатньо балів",
      "bad_msisdn":"Перевірте номер телефону",
      "ordered_cert":"Замовлені сертифікати",
      "later_cert":"Сервіс замовлення сертифікатів тимчасово недоступний. Спробуйте пізніше.",
      "insuf_max":"Максимальна сума поповнення за добу складає 3000 грн.",
      "insuf_max_op":"Шановний користувач! Максимальна сума однієї транзакції на поповнення мобільного рахунку складає 1100 грн. При цьому обмеження на кількість транзакцій немає.",      
      "next_day_queue":"Відповідно до обмежень НБУ, встановлених у військовий час, на сьогодні додаток перевищив добовий ліміт поповнень мобільних рахунків. Проте Ваш рахунок буде поповнено завтра одним із перших. Будь ласка, очікуйте на поповнення.",
      "next_day_try":"Відповідно до обмежень НБУ, встановлених у військовий час, на сьогодні додаток перевищив добовий ліміт поповнень мобільних рахунків. Будь ласка, спробуйте замовити поповнення Вашого рахунку завтра."
      


      

  }
}
</i18n>